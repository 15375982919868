import React, { useState } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

const EmailGenerator = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [recipient, setRecipient] = useState('');
    const [message, setMessage] = useState('');
    const [purpose, setPurpose] = useState('');
    const [toneAndStyle, setToneAndStyle] = useState('');
    const [keywords, setKeywords] = useState('');
    const [additionalInstructions, setAdditionalInstructions] = useState('');
    const [generatedEmail, setGeneratedEmail] = useState('');

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleGenerateEmail = async () => {
        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const response = await fetch(`${baseUrl}/generate-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    recipient,
                    message,
                    purpose,
                    toneAndStyle,
                    keywords,
                    additionalInstructions
                })
            });
            const data = await response.json();
            setGeneratedEmail(data.response.generatedEmail);
        } catch (error) {
            console.error('Error generating email:', error);
        }
    };

    return (
        <div className="relative flex flex-col min-h-screen">
            <NavBar toggleSidebar={toggleSidebar} />
            <div className="flex-1 p-4 flex flex-col">
                <div className="flex-1 bg-white shadow-md rounded-lg p-4 border-black border overflow-hidden">
                    <h2 className="text-2xl font-semibold mb-2">Email Generator</h2>
                    <p className="text-gray-600 mb-6">Generate email content using AI based on your inputs.</p>
                    <div className="flex mt-4 mb-8 h-full">
                        <div className="w-3/4 pr-4">
                            {generatedEmail ? (
                                <div className="w-full h-full border rounded p-4 bg-gray-100 overflow-scroll">
                                    <div dangerouslySetInnerHTML={{ __html: generatedEmail.replace(/\n/g, '<br>') }} />
                                </div>
                            ) : (
                                <p className="text-gray-600">Generated email will appear here...</p>
                            )}
                        </div>
                        <div className="w-1/4 pl-4 flex flex-col space-y-4">
                            <div>
                                <textarea
                                    className="w-full border rounded p-2"
                                    placeholder="Recipient"
                                    value={recipient}
                                    onChange={(e) => setRecipient(e.target.value)}
                                ></textarea>
                            </div>
                            <div>
                                <textarea
                                    className="w-full border rounded p-2"
                                    placeholder="What would you like to say in the email?"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                ></textarea>
                            </div>
                            <div>
                                <textarea
                                    className="w-full border rounded p-2"
                                    placeholder="Purpose of the email (e.g., Thank You, Follow-Up, Introduction)"
                                    value={purpose}
                                    onChange={(e) => setPurpose(e.target.value)}
                                ></textarea>
                            </div>
                            <div>
                                <select
                                    className="w-full border rounded p-2"
                                    value={toneAndStyle}
                                    onChange={(e) => setToneAndStyle(e.target.value)}
                                >
                                    <option value="" disabled selected>Tone and Style</option>
                                    <option value="formal">Formal</option>
                                    <option value="informal">Informal</option>
                                    <option value="friendly">Friendly</option>
                                    <option value="professional">Professional</option>
                                    <option value="humorous">Humorous</option>
                                </select>
                            </div>
                            <div>
                                <textarea
                                    className="w-full border rounded p-2"
                                    placeholder="Are there any specific keywords that should be included?"
                                    value={keywords}
                                    onChange={(e) => setKeywords(e.target.value)}
                                ></textarea>
                            </div>
                            <div>
                                <textarea
                                    className="w-full border rounded p-2"
                                    placeholder="Are there any additional requirements or instructions for this email?"
                                    value={additionalInstructions}
                                    onChange={(e) => setAdditionalInstructions(e.target.value)}
                                ></textarea>
                            </div>
                            <button
                                className="w-full bg-blue-500 text-white rounded p-2 hover:bg-blue-600 transition duration-200"
                                onClick={handleGenerateEmail}
                            >
                                Generate Email
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default EmailGenerator;