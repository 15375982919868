import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { msalConfig } from './authConfig';
import './index.css';
import App from './App';
import Login from './components/Login';
import reportWebVitals from './reportWebVitals';

const msalInstance = new PublicClientApplication(msalConfig);

const AuthenticatedApp = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleRedirect = async () => {
      try {
        await instance.handleRedirectPromise();
      } catch (error) {
        console.error("Error handling redirect:", error);
      } finally {
        setIsLoading(false);
      }
    };

    handleRedirect();
  }, [instance]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? <App /> : <Login />;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <AuthenticatedApp />
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
