import React, { useState } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

const MetadataKeywordGenerator = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [inputType, setInputType] = useState('url'); // Default to URL input
    const [inputValue, setInputValue] = useState('');
    const [result, setResult] = useState({});

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleInputTypeChange = (event) => {
        setInputType(event.target.value);
        setInputValue('');
    };

    const handleGenerateMetadataKeywords = async () => {
        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const response = await fetch(`${baseUrl}/generate-seo-content`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ inputType, inputValue })
            });

            const data = await response.json();
            console.log("API Response:", data);
            setResult(data.response);
        } catch (error) {
            console.error('Error generating metadata and keywords:', error);
            setResult({ error: 'Error generating metadata and keywords' });
        }
    };

    const renderResult = () => {
        return (
            Object.entries(result).map(([key, value]) => (
                <div key={key} className="mb-2">
                    <strong>{key}:</strong> {value.split('\n').map((line, index) => (<div key={index}>{line}</div>))}
                </div>
            ))
        );
    };

    return (
        <div className="relative flex flex-col min-h-screen">
            <NavBar toggleSidebar={toggleSidebar} />
            <div className="flex-1 p-4 flex flex-col">
                <div className="flex-1 bg-white shadow-md rounded-lg p-4 border-black border overflow-hidden">
                    <h2 className="text-2xl font-semibold mb-2">Metadata & Keyword Generator</h2>
                    <p className="text-gray-600 mb-6">Generate metadata and keywords from a URL or text using AI.</p>
                    <div className="flex flex-col space-y-4">
                        <div className="flex items-center space-x-4">
                            <label className="block mb-2">Input Type:</label>
                            <select
                                className="border rounded p-2"
                                value={inputType}
                                onChange={handleInputTypeChange}
                            >
                                <option value="url">URL</option>
                                <option value="text">Text</option>
                            </select>
                        </div>
                        {inputType === 'url' ? (
                            <input
                                type="text"
                                className="w-full border rounded p-2"
                                placeholder="Enter URL..."
                                value={inputValue}
                                onChange={handleInputChange}
                            />
                        ) : (
                            <textarea
                                className="w-full border rounded p-2"
                                placeholder="Enter text..."
                                value={inputValue}
                                onChange={handleInputChange}
                            />
                        )}
                        <button
                            className="w-full bg-blue-500 text-white rounded p-2"
                            onClick={handleGenerateMetadataKeywords}
                        >
                            Generate Metadata & Keywords
                        </button>
                        {Object.keys(result).length > 0 && (
                            <div className="mt-4">
                                <h3 className="text-xl font-semibold mb-2">Result</h3>
                                <div className="w-full h-full border rounded p-2 bg-gray-100 overflow-scroll">
                                    {renderResult()}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default MetadataKeywordGenerator;