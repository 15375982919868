import React from 'react';
import { Twitter, LinkedIn, Facebook } from '@mui/icons-material'; // Add icons for social media

const Footer = () => {
  return (
    <footer className="bg-white border-t py-8">
      <div className="container mx-auto flex flex-col items-center lg:flex-row justify-center px-4">
        <div className="mb-8 lg:mb-0 lg:pr-8 lg:self-start lg:pt-2 px-4"> {/* Add padding at the top */}
          <img src="/icons/nmq-logo.png" alt="Brand Logo" className="h-10 mb-4" />
        </div>
        <div className="flex flex-col lg:flex-row justify-center items-center w-full space-y-8 lg:space-y-0 lg:space-x-12 text-center lg:text-left">
          <div className="mb-8 lg:mb-0">
            <h3 className="font-semibold mb-4">Company</h3>
            <ul className="space-y-2">
              <li><a href="#" className="text-gray-600 hover:text-gray-800">About Us</a></li>
              <li><a href="#" className="text-gray-600 hover:text-gray-800">Solutions</a></li>
              <li><a href="#" className="text-gray-600 hover:text-gray-800">Insights</a></li>
            </ul>
          </div>
          <div className="mb-8 lg:mb-0">
            <h3 className="font-semibold mb-4">Tools</h3>
            <ul className="space-y-2">
              <li><a href="#" className="text-gray-600 hover:text-gray-800">AI Assistants</a></li>
              <li><a href="#" className="text-gray-600 hover:text-gray-800">AI Analytics</a></li>
              <li><a href="#" className="text-gray-600 hover:text-gray-800">AI Content Tools</a></li>
            </ul>
          </div>
          <div className="mb-8 lg:mb-0">
            <h3 className="font-semibold mb-4">Resources</h3>
            <ul className="space-y-2">
              <li><a href="#" className="text-gray-600 hover:text-gray-800">Blog</a></li>
              <li><a href="#" className="text-gray-600 hover:text-gray-800">Case Studies</a></li>
              <li><a href="#" className="text-gray-600 hover:text-gray-800">Webinars</a></li>
            </ul>
          </div>
        </div>
      </div>
      <hr className="border-gray-300 my-8" />
      <div className="container mx-auto flex flex-col lg:flex-row justify-between items-center px-4 text-center lg:text-left">
        <div className="flex space-x-4 mb-4 lg:mb-0 lg:pl-4">
          <a href="#" aria-label="Twitter">
            <Twitter className="text-gray-600 hover:text-gray-800" />
          </a>
          <a href="#" aria-label="LinkedIn">
            <LinkedIn className="text-gray-600 hover:text-gray-800" />
          </a>
          <a href="#" aria-label="Facebook">
            <Facebook className="text-gray-600 hover:text-gray-800" />
          </a>
        </div>
        <div className="text-gray-600 text-sm flex flex-col lg:flex-row items-center justify-center lg:justify-end space-y-2 lg:space-y-0 lg:space-x-4 lg:pr-4">
          <a href="#" className="hover:text-gray-800">Privacy Policy</a>
          <a href="#" className="hover:text-gray-800">Terms and Conditions</a>
          <a href="#" className="hover:text-gray-800">Code of Conduct</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;