import React, { useState } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import ReactMarkdown from 'react-markdown';

const WhitepaperContentGenerator = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [purpose, setPurpose] = useState('');
    const [targetAudience, setTargetAudience] = useState('');
    const [keyTopics, setKeyTopics] = useState('');
    const [toneAndStyle, setToneAndStyle] = useState('');
    const [length, setLength] = useState('');
    const [keywords, setKeywords] = useState('');
    const [references, setReferences] = useState('');
    const [additionalInstructions, setAdditionalInstructions] = useState('');
    const [generatedContent, setGeneratedContent] = useState(''); // Updated to use response.generatedWhitepaperContent

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleGenerateContent = async () => {
        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const response = await fetch(`${baseUrl}/generate-whitepaper-content`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    title,
                    purpose,
                    targetAudience,
                    keyTopics,
                    toneAndStyle,
                    length,
                    keywords,
                    references,
                    additionalInstructions
                })
            });
            const data = await response.json();
            setGeneratedContent(data.response.generatedWhitepaperContent);
        } catch (error) {
            console.error('Error generating whitepaper content:', error);
        }
    };

    return (
        <div className="relative flex flex-col min-h-screen">
            <NavBar toggleSidebar={toggleSidebar} />
            <div className="flex-1 p-4 flex flex-col">
                <div className="flex-1 bg-white shadow-md rounded-lg p-4 border-black border overflow-hidden">
                    <h2 className="text-2xl font-semibold mb-2">Whitepaper Content Generator</h2>
                    <p className="text-gray-600 mb-6">Create whitepaper content using generative AI.</p>
                    <div className="flex mt-4 mb-8 h-full">
                        <div className="w-3/4 pr-4">
                            {generatedContent ? (
                                <div className="w-full h-full border rounded p-4 bg-gray-100 overflow-scroll">
                                    <ReactMarkdown>{generatedContent}</ReactMarkdown>
                                </div>
                            ) : (
                                <p className="text-gray-600">Generated whitepaper content will appear here...</p>
                            )}
                        </div>
                        <div className="w-1/4 pl-4 flex flex-col space-y-4">
                            <div>
                                <textarea
                                    className="w-full border rounded p-2"
                                    placeholder="What is the title of the whitepaper?"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                ></textarea>
                            </div>
                            <div>
                                <textarea
                                    className="w-full border rounded p-2"
                                    placeholder="What is the main purpose or objective of this whitepaper?"
                                    value={purpose}
                                    onChange={(e) => setPurpose(e.target.value)}
                                ></textarea>
                            </div>
                            <div>
                                <select
                                    className="w-full border rounded p-2"
                                    value={targetAudience}
                                    onChange={(e) => setTargetAudience(e.target.value)}
                                >
                                    <option value="" disabled>Target Audience</option>
                                    <option value="executives">Executives</option>
                                    <option value="managers">Managers</option>
                                    <option value="researchers">Researchers</option>
                                    <option value="engineers">Engineers</option>
                                </select>
                            </div>
                            <div>
                                <textarea
                                    className="w-full border rounded p-2"
                                    placeholder="What are the key topics or sections that need to be included?"
                                    value={keyTopics}
                                    onChange={(e) => setKeyTopics(e.target.value)}
                                ></textarea>
                            </div>
                            <div>
                                <select
                                    className="w-full border rounded p-2"
                                    value={toneAndStyle}
                                    onChange={(e) => setToneAndStyle(e.target.value)}
                                >
                                    <option value="" disabled>Tone and Style</option>
                                    <option value="formal">Formal</option>
                                    <option value="informal">Informal</option>
                                    <option value="technical">Technical</option>
                                    <option value="persuasive">Persuasive</option>
                                </select>
                            </div>
                            <div>
                                <select
                                    className="w-full border rounded p-2"
                                    value={length}
                                    onChange={(e) => setLength(e.target.value)}
                                >
                                    <option value="" disabled>Length of the Whitepaper</option>
                                    <option value="five-to-ten-pages">Short (5-10 pages)</option>
                                    <option value="ten-to-twenty-pages">Medium (10-20 pages)</option>
                                    <option value="more-than-twenty-pages">Long (20+ pages)</option>
                                </select>
                            </div>
                            <div>
                                <textarea
                                    className="w-full border rounded p-2"
                                    placeholder="Are there any specific keywords that should be included in the content?"
                                    value={keywords}
                                    onChange={(e) => setKeywords(e.target.value)}
                                ></textarea>
                            </div>
                            <div>
                                <textarea
                                    className="w-full border rounded p-2"
                                    placeholder="Are there any specific references or data sources that should be included?"
                                    value={references}
                                    onChange={(e) => setReferences(e.target.value)}
                                ></textarea>
                            </div>
                            <div>
                                <textarea
                                    className="w-full border rounded p-2"
                                    placeholder="Are there any additional requirements or instructions for this whitepaper?"
                                    value={additionalInstructions}
                                    onChange={(e) => setAdditionalInstructions(e.target.value)}
                                ></textarea>
                            </div>
                            <button
                                className="w-full bg-blue-500 text-white rounded p-2 hover:bg-blue-600 transition duration-200"
                                onClick={handleGenerateContent}
                            >
                                Generate Whitepaper
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default WhitepaperContentGenerator;