import React from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import NavBar from './NavBar'; // Import NavBar
import Sidebar from './Sidebar'; // Import Sidebar

const Login = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch(e => {
      console.error(e);
    });
  };

  return (
    <div className="relative flex flex-col min-h-screen">
      {/* Sidebar and Navbar (non-interactive) */}

      {/* Main Content Area */}
      <div className="flex flex-1 pt-16 lg:pt-0">
        <div className="flex-1 p-8 flex flex-col lg:pl-4">
          <div className="flex flex-1 items-center justify-center bg-gray-100">
            <div className="text-center p-8 bg-white shadow-lg rounded-lg max-w-lg mx-auto">
              <img src="/icons/nmq-logo.png" alt="Brand Logo" className="h-24 mx-auto mb-6" />
              <h2 className="text-3xl font-semibold mb-6">Welcome Kamino</h2>
              <button
                onClick={handleLogin}
                className="bg-purple-600 text-white text-lg font-semibold py-3 px-8 rounded-full hover:bg-purple-700 transition"
              >
                Login with Microsoft
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;