import React, { useState } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import ReactMarkdown from 'react-markdown';

const WebsiteContentGenerator = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [pageType, setPageType] = useState('');
    const [targetAudience, setTargetAudience] = useState('');
    const [mainMessage, setMainMessage] = useState('');
    const [keySections, setKeySections] = useState('');
    const [toneAndStyle, setToneAndStyle] = useState('');
    const [callToAction, setCallToAction] = useState('');
    const [keywords, setKeywords] = useState('');
    const [additionalInstructions, setAdditionalInstructions] = useState('');
    const [generatedContent, setGeneratedContent] = useState('');

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleGenerateContent = async () => {
        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const response = await fetch(`${baseUrl}/generate-website-content`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    pageType,
                    targetAudience,
                    mainMessage,
                    keySections,
                    toneAndStyle,
                    callToAction,
                    keywords,
                    additionalInstructions
                })
            });
            const data = await response.json();
            setGeneratedContent(data.response.generatedWebsiteContent);
        } catch (error) {
            console.error('Error generating website content:', error);
        }
    };

    return (
        <div className="relative flex flex-col min-h-screen">
            <NavBar toggleSidebar={toggleSidebar} />
            <div className="flex-1 p-4 flex flex-col">
                <div className="flex-1 bg-white shadow-md rounded-lg p-4 border-black border overflow-hidden">
                    <h2 className="text-2xl font-semibold mb-2">Website Content Generator</h2>
                    <p className="text-gray-600 mb-6">Create website content using generative AI.</p>
                    <div className="flex mt-4 mb-8 h-full">
                        <div className="w-3/4 pr-4">
                            {generatedContent ? (
                                <div className="w-full h-full border rounded p-4 bg-gray-100 overflow-scroll">
                                    <ReactMarkdown>{generatedContent}</ReactMarkdown>
                                </div>
                            ) : (
                                <p className="text-gray-600">Generated website content will appear here...</p>
                            )}
                        </div>
                        <div className="w-1/4 pl-4 flex flex-col space-y-4">
                            <div>
                                <select
                                    className="w-full border rounded p-2"
                                    value={pageType}
                                    onChange={(e) => setPageType(e.target.value)}
                                >
                                    <option value="" disabled>Website Page Type</option>
                                    <option value="homepage">Homepage</option>
                                    <option value="about us">About Us</option>
                                    <option value="services">Services</option>
                                    <option value="contact">Contact</option>
                                </select>
                            </div>
                            <div>
                                <select
                                    className="w-full border rounded p-2"
                                    value={targetAudience}
                                    onChange={(e) => setTargetAudience(e.target.value)}
                                >
                                    <option value="" disabled>Target Audience</option>
                                    <option value="customers">Customers</option>
                                    <option value="clients">Clients</option>
                                    <option value="partners">Partners</option>
                                    <option value="general public">General Public</option>
                                </select>
                            </div>
                            <div>
                                <textarea
                                    className="w-full border rounded p-2"
                                    placeholder="What is the main message or purpose of this page?"
                                    value={mainMessage}
                                    onChange={(e) => setMainMessage(e.target.value)}
                                ></textarea>
                            </div>
                            <div>
                                <textarea
                                    className="w-full border rounded p-2"
                                    placeholder="What are the key sections or points that need to be included?"
                                    value={keySections}
                                    onChange={(e) => setKeySections(e.target.value)}
                                ></textarea>
                            </div>
                            <div>
                                <select
                                    className="w-full border rounded p-2"
                                    value={toneAndStyle}
                                    onChange={(e) => setToneAndStyle(e.target.value)}
                                >
                                    <option value="" disabled>Tone and Style</option>
                                    <option value="formal">Formal</option>
                                    <option value="informal">Informal</option>
                                    <option value="friendly">Friendly</option>
                                    <option value="professional">Professional</option>
                                    <option value="persuasive">Persuasive</option>
                                </select>
                            </div>
                            <div>
                                <textarea
                                    className="w-full border rounded p-2"
                                    placeholder="Is there a specific call to action for this page? (e.g., Sign Up, Contact Us, Learn More)"
                                    value={callToAction}
                                    onChange={(e) => setCallToAction(e.target.value)}
                                ></textarea>
                            </div>
                            <div>
                                <textarea
                                    className="w-full border rounded p-2"
                                    placeholder="Are there any specific keywords that should be included in the content?"
                                    value={keywords}
                                    onChange={(e) => setKeywords(e.target.value)}
                                ></textarea>
                            </div>
                            <div>
                                <textarea
                                    className="w-full border rounded p-2"
                                    placeholder="Are there any additional requirements or instructions for this page?"
                                    value={additionalInstructions}
                                    onChange={(e) => setAdditionalInstructions(e.target.value)}
                                ></textarea>
                            </div>
                            <button
                                className="w-full bg-blue-500 text-white rounded p-2 hover:bg-blue-600 transition duration-200"
                                onClick={handleGenerateContent}
                            >
                                Generate Content
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default WebsiteContentGenerator;