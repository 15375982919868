import React from 'react';
import { clsx } from 'clsx';
import ChatIcon from '@mui/icons-material/Chat';
import CampaignIcon from '@mui/icons-material/Campaign';
import EmailIcon from '@mui/icons-material/Email';
import AnalyticIcon from '@mui/icons-material/Analytics';
import HelpIcon from '@mui/icons-material/Help';
import SocialMediaIcon from '@mui/icons-material/Chat';

const categories = [
  { title: "AI Assistants", icon: HelpIcon, color: "text-blue-400" },
  { title: "Analytics", icon: AnalyticIcon, color: "text-green-400" },
  { title: "Content", icon: ChatIcon, color: "text-yellow-400" },
  { title: "Marketing", icon: CampaignIcon, color: "text-blue-400" },
  { title: "Social Media", icon: SocialMediaIcon, color: "text-red-400" },
  { title: "Email", icon: EmailIcon, color: "text-pink-400" },
];

const Sidebar = ({ isOpen, toggleSidebar, selectCategory }) => {
  return (
    <div
      className={clsx(
        "bg-gray-100 pt-4 w-60 fixed left-3 xl:relative rounded-r-lg border border-gray-200 shadow transition-transform duration-300 z-50",
        {
          "translate-x-0": isOpen,
          "-translate-x-full xl:translate-x-0": !isOpen,
        }
      )}
      style={{ height: 'calc(100vh - 9rem)', marginTop: '1rem', marginBottom: '1rem' }}
    >
      <div className="mb-8 p-4">
        <h1 className="text-2xl font-bold">AI Tools</h1>
      </div>
      <ul className="flex flex-col flex-grow p-4">
        {categories.map(({ title, icon: IconComponent, color }, index) => (
          <li
            key={index}
            className="my-4 flex items-center cursor-pointer"
            onClick={() => selectCategory(title)}
          >
            <IconComponent className={`${color} w-4 h-4 mr-4`} />
            <span className="text-lg">{title}</span>
          </li>
        ))}
      </ul>
      {/* Close button for mobile */}
      <button
        className="absolute top-4 right-4 text-gray-600 xl:hidden"
        onClick={toggleSidebar}
      >
        &#10005; {/* X icon */}
      </button>
    </div>
  );
};

export default Sidebar;