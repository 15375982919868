import React, { useState } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

const BasicTranslation = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [inputText, setInputText] = useState('');
    const [outputText, setOutputText] = useState('');
    const [targetLanguage, setTargetLanguage] = useState('es'); // Default to Spanish

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleInputChange = (event) => {
        setInputText(event.target.value);
    };

    const handleLanguageChange = (event) => {
        setTargetLanguage(event.target.value);
    };

    const handleTranslate = async () => {
        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const response = await fetch(`${baseUrl}/translate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ inputText: inputText, targetLanguage })
            });
            const data = await response.json();
            console.log('Translated text:', data.response); // Adjusted to log the correct response structure
            setOutputText(data.response); // Adjusted to set the correct response structure
        } catch (error) {
            console.error('Error translating text:', error);
            setOutputText('Error translating text');
        }
    };

    return (
        <div className="relative flex flex-col min-h-screen">
            <NavBar toggleSidebar={toggleSidebar} />
            <div className="flex-1 p-4 flex flex-col">
                <div className="flex-1 bg-white shadow-md rounded-lg p-4 border-black border overflow-hidden">
                    <h2 className="text-2xl font-semibold mb-2">Basic Translator</h2>
                    <p className="text-gray-600 mb-6">Translate text between different languages using AI.</p>
                    <div className="flex mt-4 mb-8 h-full">
                        <div className="w-1/2 pr-2">
                            <textarea
                                className="w-full h-full border rounded p-2"
                                placeholder="Enter text to translate..."
                                value={inputText}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="w-1/6 flex flex-col items-center justify-center px-2">
                            <select
                                className="w-full border rounded p-2 mb-4"
                                value={targetLanguage}
                                onChange={handleLanguageChange}
                            >
                                <option value="es">Spanish</option>
                                <option value="fr">French</option>
                                <option value="de">German</option>
                                <option value="zh">Chinese</option>
                                <option value="ja">Japanese</option>
                                <option value="ko">Korean</option>
                                <option value="ru">Russian</option>
                                <option value="ar">Arabic</option>
                                <option value="pt">Portuguese</option>
                                <option value="hi">Hindi</option>
                                {/* Add more languages as needed */}
                            </select>
                            <button
                                className="bg-blue-500 text-white rounded p-2"
                                onClick={handleTranslate}
                            >
                                Translate
                            </button>
                        </div>
                        <div className="w-1/2 pl-2">
                            <textarea
                                className="w-full h-full border rounded p-2"
                                placeholder="Translated text will appear here..."
                                value={outputText}
                                readOnly
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default BasicTranslation;
