import React, { useState } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

const ProjectPlanning = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [projectName, setProjectName] = useState('');
    const [objectives, setObjectives] = useState('');
    const [timeline, setTimeline] = useState('');
    const [team, setTeam] = useState('');
    const [resources, setResources] = useState('');
    const [constraints, setConstraints] = useState('');
    const [deliverables, setDeliverables] = useState('');
    const [projectPlan, setProjectPlan] = useState('');

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleGeneratePlan = async () => {
        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const response = await fetch(`${baseUrl}/generate-project-plan`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ projectName, objectives, timeline, team, resources, constraints, deliverables })
            });

            const data = await response.json();
            // API returns result in the `response` key
            const planJson = data.response.startsWith('```json\n') && data.response.endsWith('\n```')
                ? data.response.slice(8, -4)  // Removes ```json\n and \n``` wrappers
                : data.response;

            setProjectPlan(planJson);
        } catch (error) {
            console.error('Error generating project plan:', error);
        }
    };

    return (
        <div className="relative flex flex-col min-h-screen">
            <NavBar toggleSidebar={toggleSidebar} />
            <div className="flex-1 p-4 flex flex-col">
                <div className="flex-1 bg-white shadow-md rounded-lg p-4 border-black border overflow-hidden">
                    <h2 className="text-2xl font-semibold mb-2">Project Planning</h2>
                    <p className="text-gray-600 mb-6">Generate a concrete project plan using AI based on your inputs.</p>
                    <div className="flex mt-4 mb-8 h-full">
                        <div className="w-3/4 pr-4">
                            <pre className="w-full h-full border rounded p-2 bg-gray-100 overflow-scroll">
                                <code>{projectPlan}</code>
                            </pre>
                        </div>
                        <div className="w-1/4 pl-4 flex flex-col space-y-4">
                            <div>
                                <input
                                    type="text"
                                    className="w-full border rounded p-2"
                                    placeholder="Project Name"
                                    value={projectName}
                                    onChange={(e) => setProjectName(e.target.value)}
                                />
                            </div>
                            <div>
                                <textarea
                                    className="w-full border rounded p-2"
                                    placeholder="Objectives"
                                    value={objectives}
                                    onChange={(e) => setObjectives(e.target.value)}
                                />
                            </div>
                            <div>
                                <textarea
                                    className="w-full border rounded p-2"
                                    placeholder="Timeline"
                                    value={timeline}
                                    onChange={(e) => setTimeline(e.target.value)}
                                />
                            </div>
                            <div>
                                <textarea
                                    className="w-full border rounded p-2"
                                    placeholder="Team"
                                    value={team}
                                    onChange={(e) => setTeam(e.target.value)}
                                />
                            </div>
                            <div>
                                <textarea
                                    className="w-full border rounded p-2"
                                    placeholder="Resources"
                                    value={resources}
                                    onChange={(e) => setResources(e.target.value)}
                                />
                            </div>
                            <div>
                                <textarea
                                    className="w-full border rounded p-2"
                                    placeholder="Constraints"
                                    value={constraints}
                                    onChange={(e) => setConstraints(e.target.value)}
                                />
                            </div>
                            <div>
                                <textarea
                                    className="w-full border rounded p-2"
                                    placeholder="Deliverables"
                                    value={deliverables}
                                    onChange={(e) => setDeliverables(e.target.value)}
                                />
                            </div>
                            <button
                                className="w-full bg-blue-500 text-white rounded p-2"
                                onClick={handleGeneratePlan}
                            >
                                Generate Project Plan
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ProjectPlanning;