import React from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { useMsal } from '@azure/msal-react';

const NavBar = ({ toggleSidebar }) => {
  const { instance, accounts } = useMsal();
  const handleLogout = () => {
   
    // Clear the MSAL cache
    instance.getActiveAccount() && instance.logout();
  
    // Clear session storage and local storage to log out the user only from the client side
    localStorage.clear();
    sessionStorage.clear();
  
    // Redirect to the post logout URL
    window.location.href = "/"; // Logout Redirect URL
  };

  const isAuthenticated = accounts.length > 0;

  return (
    <div className="flex justify-between items-center py-2 px-8 bg-white shadow-sm w-full z-10">
      <div className="flex items-center">
        <button className="xl:hidden mr-4" onClick={toggleSidebar}>
          <MenuIcon className="text-gray-600" />
        </button>
        <a href="/">
          <img src="/icons/nmq-logo.png" alt="Brand Logo" className="h-8 md:h-10" />
        </a>
      </div>
      <div className="flex items-center space-x-4">
        <div className="text-sm text-gray-700 font-semibold flex items-center select-none cursor-pointer">
          Tools <span className="ml-1">&#9662;</span>
        </div>
        <div className="text-sm text-gray-700 font-semibold select-none cursor-pointer">Admin</div>
        {isAuthenticated ? (
          <button
            className="bg-purple-600 text-white text-xs md:text-sm font-semibold py-1 px-3 md:py-2 md:px-4 rounded-full focus:outline-none"
            onClick={handleLogout}
          >
            Log Out
          </button>
        ) : (
          <a
            href="/login"
            className="bg-purple-600 text-white text-xs md:text-sm font-semibold py-1 px-3 md:py-2 md:px-4 rounded-full focus:outline-none"
          >
            Log In
          </a>
        )}
      </div>
    </div>
  );
};

export default NavBar;
