import React, { useState, useCallback, useRef, useEffect } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import { useDropzone } from 'react-dropzone';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
let displayImage;

const ImageCropper = () => {
  const [activeTab, setActiveTab] = useState('upload');
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [processedImage, setProcessedImage] = useState(null);
  const [cropImage, setCropImage] = useState(null);
  const [finalImage, setFinalImage] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [fileName, setFileName] = useState(`image_${Date.now()}`);
  const [format, setFormat] = useState('PNG');
  const [originalImage, setOriginalImage] = useState(null);
  const [initialWidth, setInitialWidth] = useState(null);
  const [initialHeight, setInitialHeight] = useState(null);
  const [loading, setLoading] = useState(false);
  const [cropData, setCropData] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    rotate: 0,
    scaleX: 1,
    scaleY: 1,
  });
  const cropperRef = useRef(null);
  const imageRef = useRef(null);
  const [cropper, setCropper] = useState(null);
  const [resizeWidth, setResizeWidth] = useState(null);
  const [resizeHeight, setResizeHeight] = useState(null);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const onDrop = acceptedFiles => {
    const file = acceptedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          setImage(e.target.result);
          setOriginalImage(e.target.result);
          setInitialWidth(img.width);
          setInitialHeight(img.height);
          setResizeWidth(img.width);
          setResizeHeight(img.height);
          setProcessedImage(e.target.result);
          setActiveTab('edit');
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
    maxFiles: 1,
  });

  const handleCropperDestroy = () => {
    if (cropper) {
      cropper.destroy();
      setCropper(null);
    }
  };

  const loadImage = (src) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.src = src;
      img.onload = () => resolve(img);
    });
  };

  const resizeImage = () => {
    loadImage(image).then((img) => {
      const canvas = document.createElement('canvas');
      canvas.width = resizeWidth;
      canvas.height = resizeHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, resizeWidth, resizeHeight);
      const resizedDataUrl = canvas.toDataURL();
      setProcessedImage(resizedDataUrl);
    });
  };

  const handleUpscale = async () => {
    if (processedImage) {
      setLoading(true);
      const response = await fetch(processedImage);
      const imageBlob = await response.blob();
      const formData = new FormData();
      formData.append('image', imageBlob, fileName + '.' + format.toLowerCase());

      try {
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const res = await fetch(`${baseUrl}/generate-image-upscale`, {
          method: 'POST',
          body: formData,
        });

        const upscaledData = await res.json();
        setProcessedImage(upscaledData.response.upscaledImageUrl);
      } catch (error) {
        console.error('Error upscaling image:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    displayImage = image;
    if (activeTab === 'edit') {
      displayImage = processedImage;
    } else if (activeTab === 'crop') {
      displayImage = cropImage;
    } else if (activeTab === 'download') {
      displayImage = finalImage;
    } else {
      displayImage = finalImage || processedImage || image;
    }

    if (activeTab === 'crop' && displayImage && imageRef.current) {
      const cropperInstance = new Cropper(imageRef.current, {
        aspectRatio: 3 / 1,
        viewMode: 2,
        zoomable: true,
        rotatable: true,
        scalable: true,
        crop(event) {
          setCropData({
            x: Math.round(event.detail.x),
            y: Math.round(event.detail.y),
            width: Math.round(event.detail.width),
            height: Math.round(event.detail.height),
            rotate: event.detail.rotate,
            scaleX: event.detail.scaleX,
            scaleY: event.detail.scaleY,
          });
        },
      });
      setCropper(cropperInstance);
      return () => cropperInstance.destroy();
    } else if (activeTab !== 'crop') {
      handleCropperDestroy();
    }

    if (imageRef.current) {
      imageRef.current.src = displayImage;
    }
  }, [activeTab, processedImage, cropImage, finalImage, image]);

  const handleCrop = useCallback(() => {
    if (cropper) {
      cropper.getCroppedCanvas().toBlob((blob) => {
        const croppedUrl = URL.createObjectURL(blob);
        setCroppedImage(croppedUrl);
        setImage(croppedUrl);
        setCropImage(croppedUrl);
        setFinalImage(croppedUrl);
        setActiveTab('download');
      }, `image/${format.toLowerCase()}`);
    }
  }, [cropper, format]);

  const handleRotateLeft = () => {
    if (cropper) {
      cropper.rotate(-45);
    }
  };

  const handleRotateRight = () => {
    if (cropper) {
      cropper.rotate(45);
    }
  };

  const handleResetEditTab = () => {
    setImage(originalImage);
    setProcessedImage(originalImage);
    setResizeWidth(initialWidth);
    setResizeHeight(initialHeight);
    setActiveTab('edit');
  };

  const handleResetCropTab = () => {
    setImage(processedImage);
    setCropImage(processedImage);
    setCroppedImage(null);
    if (cropper) {
      cropper.reset();
      setCropData({
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        rotate: 0,
        scaleX: 1,
        scaleY: 1,
      });
    }
  };

  const handleZoomIn = () => {
    if (cropper) {
      cropper.zoom(0.1);
    }
  };

  const handleZoomOut = () => {
    if (cropper) {
      cropper.zoom(-0.1);
    }
  };

  const handleAspectRatio = (ratio) => {
    if (cropper) {
      cropper.setAspectRatio(ratio);
    }
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = finalImage || processedImage || croppedImage || image;
    link.download = `${fileName}.${format.toLowerCase()}`;
    link.click();
  };

  const handleResizeWidthChange = (e) => {
    setResizeWidth(Number(e.target.value));
  };

  const handleResizeHeightChange = (e) => {
    setResizeHeight(Number(e.target.value));
  };

  const handleScaleXChange = (e) => {
    const scaleX = parseFloat(e.target.value);
    if (cropper) {
      cropper.scaleX(scaleX);
      setCropData((prevData) => ({
        ...prevData,
        scaleX: scaleX,
      }));
    }
  };

  const handleScaleYChange = (e) => {
    const scaleY = parseFloat(e.target.value);
    if (cropper) {
      cropper.scaleY(scaleY);
      setCropData((prevData) => ({
        ...prevData,
        scaleY: scaleY,
      }));
    }
  };

  const renderTabContent = () => {
    const displayImage = activeTab === 'edit' ? processedImage : activeTab === 'crop' ? processedImage : finalImage || processedImage || image;

    switch (activeTab) {
      case 'upload':
        return (
          <div className="border p-4 rounded-lg mb-4 flex-1">
            <div
              {...getRootProps()}
              className="w-full h-64 md:h-80 lg:h-96 flex items-center justify-center bg-gray-100 border-2 border-dashed border-gray-200 rounded-lg cursor-pointer"
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop your image here ...</p>
              ) : (
                <p>
                  Drop your image here or <span className="cursor-pointer font-bold">choose from files</span>
                </p>
              )}
            </div>
          </div>
        );
      case 'edit':
        return (
          <div className="flex flex-col lg:flex-row flex-1">
            <div className="relative flex-1 border p-4 rounded-lg mb-4 lg:mb-0 flex justify-center items-center w-full">
              {loading ? (
                <p>Loading...</p>
              ) : (
                displayImage && (
                  <img ref={imageRef} src={displayImage} alt="Source" />
                )
              )}
            </div>
            <div className="lg:ml-4 flex flex-col justify-between w-full max-w-md">
              <div className="flex mb-4">
                <div className="mr-4 flex-1">
                  <label className="block mb-1">Resize Width</label>
                  <input
                    type="number"
                    placeholder="Width"
                    className="border rounded p-2 w-full"
                    value={resizeWidth || ''}
                    onChange={handleResizeWidthChange}
                    disabled={loading}
                  />
                </div>
                <div className="flex-1">
                  <label className="block mb-1">Resize Height</label>
                  <input
                    type="number"
                    placeholder="Height"
                    className="border rounded p-2 w-full"
                    value={resizeHeight || ''}
                    onChange={handleResizeHeightChange}
                    disabled={loading}
                  />
                </div>
              </div>
              <button
                className="bg-blue-500 text-white p-2 rounded w-full mb-2"
                onClick={resizeImage}
                disabled={loading}
              >
                Resize Image
              </button>
              <button
                className="bg-green-500 text-white p-2 rounded w-full mb-2"
                onClick={handleUpscale}
                disabled={loading}
              >
                Upscale Image
              </button>
              <button
                className="bg-blue-500 text-white p-2 rounded w-full mb-2"
                onClick={() => {
                  setCropImage(displayImage); // Use edited image for cropping
                  setActiveTab('crop');
                }}
                disabled={loading}
              >
                Continue to Crop
              </button>
              <button className="bg-yellow-500 text-white p-2 rounded w-full mb-2" onClick={handleResetEditTab} disabled={loading}>
                Reset
              </button>
            </div>
          </div>
        );
      case 'crop':
        return (
          <div className="flex flex-col lg:flex-row flex-1">
            <div className="relative flex-1 border p-4 rounded-lg mb-4 lg:mb-0 flex justify-center items-center w-full">
              {displayImage && (
                <img ref={imageRef} src={displayImage} alt="Source" />
              )}
            </div>
            <div className="lg:ml-4 flex flex-col justify-between w-full max-w-md">
              <div className="flex mb-4">
                <div className="mr-4 flex-1">
                  <label className="block mb-1">Aspect Ratio</label>
                  <select
                    className="border rounded p-2 w-full"
                    onChange={(e) => handleAspectRatio(parseFloat(e.target.value))}
                  >
                    <option value={16 / 9}>16:9</option>
                    <option value={4 / 3}>4:3</option>
                    <option value={1}>1:1</option>
                    <option value={2 / 3}>2:3</option>
                    <option value="NaN">Free</option>
                  </select>
                </div>
              </div>
              <button className="bg-green-500 text-white p-2 rounded w-full mb-2" onClick={handleRotateLeft} disabled={loading}>
                Rotate Left
              </button>
              <button className="bg-green-500 text-white p-2 rounded w-full mb-2" onClick={handleRotateRight} disabled={loading}>
                Rotate Right
              </button>
              <button className="bg-blue-500 text-white p-2 rounded w-full mb-2" onClick={handleCrop} disabled={loading}>
                Crop Image
              </button>
              <button className="bg-yellow-500 text-white p-2 rounded w-full mb-2" onClick={handleResetCropTab} disabled={loading}>
                Reset
              </button>
              <button className="bg-purple-500 text-white p-2 rounded w-full mb-2" onClick={handleZoomIn} disabled={loading}>
                Zoom In
              </button>
              <button className="bg-purple-500 text-white p-2 rounded w-full mb-2" onClick={handleZoomOut} disabled={loading}>
                Zoom Out
              </button>
              <div className="flex flex-col space-y-2 mt-4">
                <div className="flex items-center">
                  <label className="w-16">X:</label>
                  <input type="number" value={cropData.x} readOnly className="flex-1 p-1 border rounded" />
                </div>
                <div className="flex items-center">
                  <label className="w-16">Y:</label>
                  <input type="number" value={cropData.y} readOnly className="flex-1 p-1 border rounded" />
                </div>
                <div className="flex items-center">
                  <label className="w-16">Width:</label>
                  <input type="number" value={cropData.width} readOnly className="flex-1 p-1 border rounded" />
                </div>
                <div className="flex items-center">
                  <label className="w-16">Height:</label>
                  <input type="number" value={cropData.height} readOnly className="flex-1 p-1 border rounded" />
                </div>
                <div className="flex items-center">
                  <label className="w-16">Rotate:</label>
                  <input type="number" value={cropData.rotate} readOnly className="flex-1 p-1 border rounded" />
                </div>
                <div className="flex items-center">
                  <label className="w-16">ScaleX:</label>
                  <input
                    type="number"
                    step="0.1"
                    value={cropData.scaleX}
                    onChange={handleScaleXChange}
                    className="flex-1 p-1 border rounded"
                  />
                </div>
                <div className="flex items-center">
                  <label className="w-16">ScaleY:</label>
                  <input
                    type="number"
                    step="0.1"
                    value={cropData.scaleY}
                    onChange={handleScaleYChange}
                    className="flex-1 p-1 border rounded"
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case 'download':
        return (
          <div className="flex flex-col lg:flex-row flex-1">
            <div className="flex-1 border p-4 rounded-lg flex justify-center items-center mb-4 lg:mb-0 w-full">
              {finalImage || processedImage || croppedImage || image ? (
                <img
                  src={finalImage || processedImage || croppedImage || image}
                  alt="Final"
                  className="object-contain"
                />
              ) : (
                <p>No image available</p>
              )}
            </div>
            <div className="lg:ml-4 flex flex-col justify-center w-full max-w-md">
              <p className="text-center mb-2">Your image is ready!</p>
              <div className="mb-4">
                <label className="block mb-1">File name</label>
                <input
                  type="text"
                  placeholder="File name"
                  className="border rounded p-2 w-full"
                  value={fileName}
                  onChange={(e) => setFileName(e.target.value)}
                />
              </div>
              <div className="flex mb-4">
                <label className="block mb-1 mr-2">Format</label>
                <button className={`border rounded p-2 mr-2 ${format === 'JPG' ? 'bg-gray-300' : ''}`} onClick={() => setFormat('JPG')}>
                  JPG
                </button>
                <button
                  className={`border rounded p-2 ${format === 'PNG' ? 'bg-gray-300' : ''}`}
                  onClick={() => setFormat('PNG')}
                >
                  PNG
                </button>
              </div>
              <button className="bg-blue-500 text-white p-2 rounded" onClick={handleDownload}>
                Download
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="relative flex flex-col min-h-screen">
      <NavBar toggleSidebar={toggleSidebar} />
      <div className="flex-1 p-4 flex flex-col">
        <div className="flex-1 bg-white shadow-md rounded-lg p-4 border-black border overflow-hidden">
          <h2 className="text-2xl font-semibold mb-2">Image Cropper</h2>
          <p className="text-gray-600 mb-6">Enhance visual appeal and optimize content layout effortlessly.</p>
          {renderTabContent()}
          <div className="flex justify-around mt-4 mb-8">
            <span
              className={`text-lg text-gray-800 relative ${activeTab === 'upload' ? '' : 'cursor-pointer'}`}
              onClick={() => setActiveTab('upload')}
            >
              Upload
              {activeTab === 'upload' && (
                <span className="absolute left-1/2 -translate-x-1/2 -bottom-2 border-t-2 border-gray-800 mt-1 w-20" />
              )}
            </span>
            <span
              className={`text-lg text-gray-800 relative ${activeTab === 'edit' ? '' : 'cursor-pointer'}`}
              onClick={() => setActiveTab('edit')}
              disabled={!image}
            >
              Edit
              {activeTab === 'edit' && (
                <span className="absolute left-1/2 -translate-x-1/2 -bottom-2 border-t-2 border-gray-800 mt-1 w-20" />
              )}
            </span>
            <span
              className={`text-lg text-gray-800 relative ${activeTab === 'crop' ? '' : 'cursor-pointer'}`}
              onClick={() => {
                setCropImage(displayImage);
                setActiveTab('crop');
              }}
              disabled={!image}
            >
              Crop
              {activeTab === 'crop' && (
                <span className="absolute left-1/2 -translate-x-1/2 -bottom-2 border-t-2 border-gray-800 mt-1 w-20" />
              )}
            </span>
            <span
              className={`text-lg text-gray-800 relative ${activeTab === 'download' ? '' : 'cursor-pointer'}`}
              onClick={() => {
                setFinalImage(cropImage || processedImage);
                setActiveTab('download');
              }}
              disabled={!image}
            >
              Download
              {activeTab === 'download' && (
                <span className="absolute left-1/2 -translate-x-1/2 -bottom-2 border-t-2 border-gray-800 mt-1 w-24" />
              )}
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ImageCropper;