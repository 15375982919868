import React, { useState } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import * as XLSX from 'xlsx';

const fetchAltText = async (imageUrl) => {
    try {
        console.log(`Fetching alt text for URL: ${imageUrl}`); // Debug log
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const response = await fetch(`${baseUrl}/generate-alt-text`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ imageUrl })
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log(`Received data for URL: ${imageUrl}`, data); // Debug log

        // Ensure the response structure is as expected and parse the alt text
        if (data.hasOwnProperty('response')) {
            const altText = data.response.match(/alt="(.*)"/)[1];
            return altText;
        } else {
            throw new Error('Malformed response structure');
        }
    } catch (error) {
        console.error('Error generating alt text:', error);
        return 'Error generating alt text';
    }
};

const AltTextGenerator = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [textAreaValue, setTextAreaValue] = useState('');
    const [results, setResults] = useState([]);
    const [processing, setProcessing] = useState([]);
    const [isGenerating, setIsGenerating] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleTextAreaChange = (event) => {
        setTextAreaValue(event.target.value);
    };

    const handleGenerateAltTexts = async () => {
        const urls = textAreaValue.split('\n').filter(url => url.trim() !== '');
        console.log('URLs to process:', urls); // Debug log

        setProcessing(urls.map(url => ({ imageUrl: url, status: 'processing' })));
        setIsGenerating(true);

        const generatedAltTexts = await Promise.all(urls.map(async (url) => {
            const altText = await fetchAltText(url);
            setProcessing(prevState =>
                prevState.map(item => item.imageUrl === url ? { ...item, status: 'completed' } : item)
            );
            return { imageUrl: url, altText };
        }));

        console.log('Generated Alt Texts:', generatedAltTexts); // Debug log
        setResults(generatedAltTexts);
    };

    const handleDownload = () => {
        const worksheet = XLSX.utils.json_to_sheet(results);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Alt Texts');
        XLSX.writeFile(workbook, 'alt_texts.xlsx');
    };

    return (
        <div className="relative flex flex-col min-h-screen">
            <NavBar toggleSidebar={toggleSidebar} />
            <div className="flex-1 p-4 flex flex-col">
                <div className="flex-1 bg-white shadow-md rounded-lg p-4 border-black border overflow-hidden">
                    <h2 className="text-2xl font-semibold mb-2">Alt Text Generator</h2>
                    <p className="text-gray-600 mb-6">
                        Generate alt text for images using AI. Enter each image URL on a new line.
                    </p>
                    <div className="flex mt-4 mb-8 h-full">
                        <div className="w-full pr-4">
                            <div className="space-y-4">
                                {!isGenerating && (
                                    <>
                                        <textarea
                                            className="w-full h-40 border rounded p-2"
                                            placeholder="Enter image URLs, each on a new line..."
                                            value={textAreaValue}
                                            onChange={handleTextAreaChange}
                                        />
                                        <button
                                            className="w-full bg-blue-500 text-white rounded p-2"
                                            onClick={handleGenerateAltTexts}
                                        >
                                            Generate Alt Texts
                                        </button>
                                    </>
                                )}
                                {isGenerating && (
                                    <>
                                        <button
                                            className="w-full bg-green-500 text-white rounded p-2 mb-4"
                                            onClick={handleDownload}
                                        >
                                            Download Results
                                        </button>
                                        <div className="space-y-4">
                                            {processing.map((process, index) => (
                                                <div key={index} className="mt-4">
                                                    <p className="text-gray-800">URL: {process.imageUrl}</p>
                                                    <p className="text-gray-800">
                                                        Status: {process.status === 'processing' ? 'Processing...' : 'Completed'}
                                                    </p>
                                                    {index < processing.length - 1 && <hr className="my-4" />}
                                                </div>
                                            ))}
                                            {results.map((result, index) => (
                                                <div key={index} className="mt-4">
                                                    <img src={result.imageUrl} alt={result.altText} className="mb-2" />
                                                    <p className="text-gray-800">URL: {result.imageUrl}</p>
                                                    <p className="text-gray-800">Alt Text: {result.altText}</p>
                                                    {index < results.length - 1 && <hr className="my-4" />}
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default AltTextGenerator;
