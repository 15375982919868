import React from 'react';
import ToolCard from './ToolCard';
import CropIcon from '@mui/icons-material/Crop';
import ArticleIcon from '@mui/icons-material/Article';
import QrCodeIcon from '@mui/icons-material/QrCode';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import WebIcon from '@mui/icons-material/Web';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import SearchIcon from '@mui/icons-material/Search';
import DescriptionIcon from '@mui/icons-material/Description';
import SubjectIcon from '@mui/icons-material/Subject';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import CampaignIcon from '@mui/icons-material/Campaign';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import PredictionIcon from '@mui/icons-material/Analytics';
import ProjectPlannerIcon from '@mui/icons-material/Inventory';
import SummaryIcon from '@mui/icons-material/Summarize';

const tools = [
  {
    icon: CropIcon,
    title: 'Image Cropper',
    description: 'Automatically crops images to desired dimensions, optimizing for aesthetics and use-case.',
    color: '#ff9800',
    category: 'Content',
    link: '/image-cropper'
  },
  {
    icon: ArticleIcon,
    title: 'Blog Post Generator',
    description: 'Generates comprehensive and engaging blog posts to maintain a regular content schedule.',
    color: '#4caf50',
    category: 'Content',
    link: '/blog-post-generator'
  },
  {
    icon: QrCodeIcon,
    title: 'QR Generator',
    description: 'Generates QR codes for easy access to digital content and enhanced customer interaction.',
    color: '#f44336',
    category: 'Marketing',
    link: '/qr-code-generator'
  },
  {
    icon: TextFieldsIcon,
    title: 'Inline Content Creator',
    description: 'Generates content inline with existing text, ensuring coherence and contextual relevance.',
    color: '#3f51b5',
    category: 'Content',
    link: '/inline-content-creator'
  },
  {
    icon: AlternateEmailIcon,
    title: 'Alt Text Generator',
    description: 'Make images accessible. AI creates captions and alt text with ease.',
    color: '#9c27b0',
    category: 'Content',
    link: '/alt-text-generator'
  },
  {
    icon: WebIcon,
    title: 'Website Content Generator',
    description: 'Produces SEO-friendly web content, ensuring consistent and high-quality material.',
    color: '#03a9f4',
    category: 'Content',
    link: '/website-content-generator'
  },
  {
    icon: GTranslateIcon,
    title: 'Basic Translation',
    description: 'Provides quick translations for multiple languages, enhancing communication and reach.',
    color: '#8bc34a',
    category: 'Marketing',
    link: '/basic-translation'
  },
  {
    icon: SearchIcon,
    title: 'Metadata & Keyword Generation',
    description: 'Generates optimized metadata and keywords to improve search engine rankings.',
    color: '#ff5722',
    category: 'Marketing',
    link: '/metadata-keyword-generator'
  },
  {
    icon: DescriptionIcon,
    title: 'Whitepaper Content Generator',
    description: 'Produces in-depth and authoritative whitepapers to establish thought leadership.',
    color: '#795548',
    category: 'Marketing',
    link: '/whitepaper-content-generator'
  },
  {
    icon: SubjectIcon,
    title: 'Email Generator',
    description: 'Effortless campaigns, powerful results. AI crafts emails that connect.',
    color: '#607d8b',
    category: 'Email',
    link: '/email-generator'
  },
  {
    icon: InsertPhotoIcon,
    title: 'Image Generation',
    description: 'Produces engaging visual content to aid in image products and marketing.',
    color: '#009688',
    category: 'Marketing',
    link: '/image-generation'
  },
  {
    icon: CampaignIcon,
    title: 'Paid Ad Content Generator',
    description: 'Analyze the possible outcome and traffic of your e-mail campaign.',
    color: '#e91e63',
    category: 'Marketing'
  },
  {
    icon: SocialDistanceIcon,
    title: 'Social Media Post Generator',
    description: 'Creates compelling social media posts, tailored for different platforms to increase engagement.',
    color: '#cddc39',
    category: 'Social Media',
    link: '/social-media-post-generator'
  },
  {
    icon: PredictionIcon,
    title: 'Talk with Your Data',
    description: 'Provides insights into future trends and outcomes, aiding in strategic decision-making.',
    color: '#ffeb3b',
    category: 'Analytics'
  },
  {
    icon: ProjectPlannerIcon,
    title: 'Project Planning',
    description: 'Optimize your project planning and scheduling with AI-driven insights and recommendations.',
    color: '#795548',
    category: 'AI Assistants',
    link: '/project-planning'
  }
];

const ToolGrid = ({ selectedCategory }) => {
  return (
    <div className="p-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      {tools
        .filter(tool => selectedCategory === '' || tool.category === selectedCategory)
        .map((tool, index) => (
          <a key={index} href={tool.link}>
            <ToolCard icon={tool.icon} title={tool.title} description={tool.description} color={tool.color} />
          </a>
        ))}
    </div>
  );
};

export default ToolGrid;