import React, { useState } from 'react';
import NavBar from '../components/NavBar';
import Sidebar from '../components/Sidebar';
import ToolGrid from '../components/ToolGrid';
import SearchIcon from '@mui/icons-material/Search';
import Footer from '../components/Footer';

const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const selectCategory = (category) => {
    setSelectedCategory(category);
    setIsSidebarOpen(false); // Close the sidebar on mobile after selecting a category
  };

  return (
    <div className="relative flex flex-col min-h-screen">
      <NavBar toggleSidebar={toggleSidebar} />
      <div className="flex flex-1 pt-16 xl:pt-0">
        {isSidebarOpen && (
          <div
            className="fixed inset-0 z-40 bg-black bg-opacity-50 lg:hidden"
            onClick={toggleSidebar}
          ></div>
        )}
        <Sidebar
          isOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
          selectCategory={selectCategory}
        />
        <div className="flex-1 p-4 flex flex-col lg:pl-4">
          {/* <div className="relative w-full mb-4">
            <span className="absolute inset-y-0 left-3 flex items-center">
              <SearchIcon className="text-gray-400" />
            </span>
            <input
              type="text"
              placeholder="Search all tools"
              className="pl-10 py-2 w-full border rounded-full focus:outline-none focus:ring-2 focus:ring-gray-300"
            />
          </div> */}
          <div className="w-full">
            <ToolGrid selectedCategory={selectedCategory} />
          </div>
        </div>
      </div>
      <Footer /> 
    </div>
  );
};

export default Dashboard;