import React, { useState, useRef } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

const InlineContentCreator = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [content, setContent] = useState('');
    const textAreaRef = useRef(null);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleContentChange = (e) => {
        setContent(e.target.value);
    };

    const handleRewrite = async () => {
        const textArea = textAreaRef.current;
        const start = textArea.selectionStart;
        const end = textArea.selectionEnd;
        const selectedText = content.substring(start, end);

        if (selectedText) {
            try {
                const baseUrl = process.env.REACT_APP_API_BASE_URL;
                const response = await fetch(`${baseUrl}/generate-inline-content`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        text: content,
                        selectedText: selectedText
                    })
                });
                const data = await response.json();
                const rewrittenText = data.response.rewrittenText;
                const newContent = content.substring(0, start) + rewrittenText + content.substring(end);
                setContent(newContent);
                highlightRewrittenText(start, rewrittenText.length);
            } catch (error) {
                console.error('Error rewriting text:', error);
            }
        }
    };

    const highlightRewrittenText = (start, length) => {
        const textArea = textAreaRef.current;
        textArea.setSelectionRange(start, start + length);
        textArea.focus();
    };

    return (
        <div className="relative flex flex-col min-h-screen">
            <NavBar toggleSidebar={toggleSidebar} />
            <div className="flex-1 p-4 flex flex-col">
                <div className="flex-1 bg-white shadow-md rounded-lg p-4 border-black border overflow-hidden">
                    <h2 className="text-2xl font-semibold mb-2">Inline Content Creator</h2>
                    <p className="text-gray-600 mb-6">Paste your content and select parts of it to be re-written by AI.</p>
                    <div className="flex mt-4 mb-8 h-full">
                        <div className="w-full">
                            <textarea
                                ref={textAreaRef}
                                className="w-full h-96 border rounded p-2"
                                placeholder="Paste or type your content here..."
                                value={content}
                                onChange={handleContentChange}
                            ></textarea>
                        </div>
                    </div>
                    <div className="flex justify-end">
                        <button
                            className="bg-blue-500 text-white rounded p-2 hover:bg-blue-600 transition duration-200"
                            onClick={handleRewrite}
                        >
                            Rewrite Selected Text
                        </button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default InlineContentCreator;