import React, { useState } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

const ImageGeneration = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [description, setDescription] = useState('');
    const [targetAudience, setTargetAudience] = useState('');
    const [style, setStyle] = useState('');
    const [generatedImage, setGeneratedImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleGenerateImage = async () => {
        setIsLoading(true);
        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const response = await fetch(`${baseUrl}/generate-image`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ description, targetAudience, style })
            });
            const data = await response.json();
            console.log('API Response:', data);

            setGeneratedImage(data.response.imageUrl);
        } catch (error) {
            console.error('Error generating image:', error);
            setGeneratedImage(null); // reset image if there's an error
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="relative flex flex-col min-h-screen">
            <NavBar toggleSidebar={toggleSidebar} />
            <div className="flex-1 p-4 flex flex-col">
                <div className="flex-1 bg-white shadow-md rounded-lg p-4 border-black border overflow-hidden">
                    <h2 className="text-2xl font-semibold mb-2">Media Generation</h2>
                    <p className="text-gray-600 mb-6">Generate images using generative AI based on your description.</p>
                    <div className="flex mt-4 mb-8 h-full">
                        <div className="w-3/4 pr-4 border rounded p-2">
                            {isLoading ? (
                                <p>Loading...</p>
                            ) : generatedImage ? (
                                <img src={generatedImage} alt="Generated" className="w-full h-full object-cover rounded" />
                            ) : (
                                <p className="text-gray-600">Generated image will appear here...</p>
                            )}
                        </div>
                        <div className="w-1/4 pl-4 flex flex-col space-y-4">
                            <div>
                                <textarea
                                    className="w-full border rounded p-2"
                                    placeholder="Describe the image you want to generate..."
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                ></textarea>
                            </div>
                            <div>
                                <select
                                    className="w-full border rounded p-2"
                                    value={targetAudience}
                                    onChange={(e) => setTargetAudience(e.target.value)}
                                >
                                    <option value="" disabled>Target Audience</option>
                                    <option value="young professionals">Young Professionals</option>
                                    <option value="students">Students</option>
                                    <option value="health enthusiasts">Health Enthusiasts</option>
                                    <option value="general audience">General Audience</option>
                                </select>
                            </div>
                            <div>
                                <select
                                    className="w-full border rounded p-2"
                                    value={style}
                                    onChange={(e) => setStyle(e.target.value)}
                                >
                                    <option value="" disabled>Style</option>
                                    <option value="formal">Formal</option>
                                    <option value="informal">Informal</option>
                                    <option value="friendly">Friendly</option>
                                    <option value="professional">Professional</option>
                                    <option value="humorous">Humorous</option>
                                </select>
                            </div>
                            <button
                                className="w-full bg-blue-500 text-white rounded p-2 hover:bg-blue-600 transition duration-200"
                                onClick={handleGenerateImage}
                                disabled={isLoading}
                            >
                                {isLoading ? 'Generating...' : 'Generate Image'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ImageGeneration;