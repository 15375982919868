// src/authConfig.js
export const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_SSO_CLIENT_ID,
      authority: `https://login.microsoftonline.com/${process.env.REACT_APP_SSO_TENANT_ID}`,
      redirectUri: process.env.REACT_APP_SSO_REDIRECT_URI,
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
    },
  };
  
  export const loginRequest = {
    scopes: ["User.Read"],
  };
  