import React from 'react';
import { Icon } from '@mui/material';

const ToolCard = ({ icon: IconComponent, title, description, color }) => {
  return (
    <div className="relative rounded-lg shadow-md overflow-hidden h-full p-4 border bg-white">
      <div className="flex items-start">
        <IconComponent style={{ color, width: '40px', height: '40px', marginRight: '16px' }} />
        <div className="flex flex-col">
          <h2 className="text-lg font-semibold mb-1">{title}</h2>
          <p className="text-sm text-gray-600 line-clamp-4">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ToolCard;