import React, { useState } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

const SocialMediaPostGenerator = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [platforms, setPlatforms] = useState([]);
    const [targetAudience, setTargetAudience] = useState('');
    const [mainMessage, setMainMessage] = useState('');
    const [toneAndStyle, setToneAndStyle] = useState('');
    const [length, setLength] = useState('');
    const [keywords, setKeywords] = useState('');
    const [callToAction, setCallToAction] = useState('');
    const [additionalInstructions, setAdditionalInstructions] = useState('');
    const [generatedPost, setGeneratedPost] = useState('');

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handlePlatformChange = (e) => {
        const value = e.target.value;
        setPlatforms((prevPlatforms) =>
            prevPlatforms.includes(value)
                ? prevPlatforms.filter((platform) => platform !== value)
                : [...prevPlatforms, value]
        );
    };

    const handleGeneratePosts = async () => {
        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const response = await fetch(`${baseUrl}/generate-social-media-post`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    platforms,
                    targetAudience,
                    mainMessage,
                    toneAndStyle,
                    length,
                    keywords,
                    callToAction,
                    additionalInstructions
                })
            });
            const data = await response.json();
            setGeneratedPost(data.response.generatedSocialMediaPost);
        } catch (error) {
            console.error('Error generating social media post:', error);
        }
    };

    return (
        <div className="relative flex flex-col min-h-screen">
            <NavBar toggleSidebar={toggleSidebar} />
            <div className="flex-1 p-4 flex flex-col">
                <div className="flex-1 bg-white shadow-md rounded-lg p-4 border-black border overflow-hidden">
                    <h2 className="text-2xl font-semibold mb-2">Social Media Post Generator</h2>
                    <p className="text-gray-600 mb-6">Create social media posts using generative AI.</p>
                    <div className="flex mt-4 mb-8 h-full">
                        <div className="w-3/4 pr-4">
                            {generatedPost ? (
                                <div className="w-full h-full border rounded p-4 bg-gray-100 overflow-scroll">
                                    <div dangerouslySetInnerHTML={{ __html: generatedPost.replace(/\n/g, '<br>') }} />
                                </div>
                            ) : (
                                <p className="text-gray-600">Generated social media post will appear here...</p>
                            )}
                        </div>
                        <div className="w-1/4 pl-4 flex flex-col space-y-4">
                            <div>
                                <label className="block mb-2">Platform</label>
                                <div className="flex flex-col">
                                    <label className="mb-1">
                                        <input
                                            type="checkbox"
                                            value="facebook"
                                            className="mr-2"
                                            onChange={handlePlatformChange}
                                        />
                                        Facebook
                                    </label>
                                    <label className="mb-1">
                                        <input
                                            type="checkbox"
                                            value="twitter"
                                            className="mr-2"
                                            onChange={handlePlatformChange}
                                        />
                                        Twitter
                                    </label>
                                    <label className="mb-1">
                                        <input
                                            type="checkbox"
                                            value="instagram"
                                            className="mr-2"
                                            onChange={handlePlatformChange}
                                        />
                                        Instagram
                                    </label>
                                    <label className="mb-1">
                                        <input
                                            type="checkbox"
                                            value="linkedin"
                                            className="mr-2"
                                            onChange={handlePlatformChange}
                                        />
                                        LinkedIn
                                    </label>
                                </div>
                            </div>
                            <div>
                                <select
                                    className="w-full border rounded p-2"
                                    value={targetAudience}
                                    onChange={(e) => setTargetAudience(e.target.value)}
                                >
                                    <option value="" disabled>Target Audience</option>
                                    <option value="general audience">General Audience</option>
                                    <option value="customers">Customers</option>
                                    <option value="clients">Clients</option>
                                    <option value="followers">Followers</option>
                                </select>
                            </div>
                            <div>
                                <textarea
                                    className="w-full border rounded p-2"
                                    placeholder="What is the main message or theme of this post?"
                                    value={mainMessage}
                                    onChange={(e) => setMainMessage(e.target.value)}
                                ></textarea>
                            </div>
                            <div>
                                <select
                                    className="w-full border rounded p-2"
                                    value={toneAndStyle}
                                    onChange={(e) => setToneAndStyle(e.target.value)}
                                >
                                    <option value="" disabled selected>Tone and Style</option>
                                    <option value="formal">Formal</option>
                                    <option value="informal">Informal</option>
                                    <option value="friendly">Friendly</option>
                                    <option value="professional">Professional</option>
                                    <option value="humorous">Humorous</option>
                                </select>
                            </div>
                            <div>
                                <select
                                    className="w-full border rounded p-2"
                                    value={length}
                                    onChange={(e) => setLength(e.target.value)}
                                >
                                    <option value="" disabled selected>Length of the Post</option>
                                    <option value="short">Short (50-100 characters)</option>
                                    <option value="medium">Medium (100-250 characters)</option>
                                    <option value="long">Long (250+ characters)</option>
                                </select>
                            </div>
                            <div>
                                <textarea
                                    className="w-full border rounded p-2"
                                    placeholder="Are there any specific keywords or hashtags that should be included?"
                                    value={keywords}
                                    onChange={(e) => setKeywords(e.target.value)}
                                ></textarea>
                            </div>
                            <div>
                                <textarea
                                    className="w-full border rounded p-2"
                                    placeholder="Is there a specific call to action for this post? (e.g., Like, Share, Comment)"
                                    value={callToAction}
                                    onChange={(e) => setCallToAction(e.target.value)}
                                ></textarea>
                            </div>
                            <div>
                                <textarea
                                    className="w-full border rounded p-2"
                                    placeholder="Are there any additional requirements or instructions for this post?"
                                    value={additionalInstructions}
                                    onChange={(e) => setAdditionalInstructions(e.target.value)}
                                ></textarea>
                            </div>
                            <button
                                className="w-full bg-blue-500 text-white rounded p-2 hover:bg-blue-600 transition duration-200"
                                onClick={handleGeneratePosts}
                            >
                                Generate Posts
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default SocialMediaPostGenerator;