import React, { useState } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import QRCode from 'qrcode.react';

const QRGenerator = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [urls, setUrls] = useState(['']);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleUrlChange = (index, event) => {
        const newUrls = urls.slice();
        newUrls[index] = event.target.value;
        setUrls(newUrls);
    };

    const handleAddUrl = () => {
        setUrls([...urls, '']);
    };

    const handleDownload = (index) => {
        const canvas = document.getElementById(`qrCodeCanvas-${index}`);
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `qr_code_${index}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <div className="relative flex flex-col min-h-screen">
            <NavBar toggleSidebar={toggleSidebar} />
            <div className="flex-1 p-4 flex flex-col">
                <div className="flex-1 bg-white shadow-md rounded-lg p-4 border-black border overflow-hidden">
                    <h2 className="text-2xl font-semibold mb-2">QR Code Generator</h2>
                    <p className="text-gray-600 mb-6">Generate QR codes for multiple URLs.</p>
                    <div className="flex mt-4 mb-8 h-full">
                        <div className="w-full pr-4">
                            <div className="space-y-4">
                                {urls.map((url, index) => (
                                    <div key={index} className="space-y-2">
                                        <div className="flex items-center space-x-4">
                                            <input
                                                type="text"
                                                className="flex-1 w-3/4 border rounded p-2"
                                                placeholder={`Enter URL ${index + 1}`}
                                                value={url}
                                                onChange={event => handleUrlChange(index, event)}
                                            />
                                            {url.trim() !== '' && (
                                                <div className="flex items-center space-x-2">
                                                    <QRCode id={`qrCodeCanvas-${index}`} value={url} size={96} />
                                                    <button
                                                        className="bg-green-500 text-white rounded p-2"
                                                        onClick={() => handleDownload(index)}
                                                    >
                                                        Download
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                        {index < urls.length - 1 && <hr className="my-4"/>}
                                    </div>
                                ))}
                                <button
                                    className="w-full bg-blue-500 text-white rounded p-2"
                                    onClick={handleAddUrl}
                                >
                                    Add Another URL
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default QRGenerator;
