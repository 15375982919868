import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import Dashboard from './pages/Dashboard';
import ImageCropper from './pages/ImageCropper';
import BlogPostGenerator from './pages/BlogPostGenerator';
import WebsiteContentGenerator from './pages/WebsiteContentGenerator';
import WhitepaperContentGenerator from './pages/WhitepaperContentGenerator';
import SocialMediaPostGenerator from './pages/SocialMediaPostGenerator';
import QRGenerator from './pages/QRGenerator';
import AltTextGenerator from './pages/AltTextGenerator';
import BasicTranslation from './pages/BasicTranslation';
import MetadataKeywordGenerator from './pages/MetadataKeywordGenerator';
import ImageGeneration from './pages/ImageGeneration';
import ProjectPlanning from './pages/ProjectPlanning';
import InlineContentCreator from './pages/InlineContentCreator';
import EmailGenerator from './pages/EmailGenerator';

function App() {
  const isAuthenticated = useIsAuthenticated();

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />} />
          <Route path="/image-cropper" element={isAuthenticated ? <ImageCropper /> : <Navigate to="/login" />} />
          <Route path="/blog-post-generator" element={isAuthenticated ? <BlogPostGenerator /> : <Navigate to="/login" />} />
          <Route path="/website-content-generator" element={isAuthenticated ? <WebsiteContentGenerator /> : <Navigate to="/login" />} />
          <Route path="/whitepaper-content-generator" element={isAuthenticated ? <WhitepaperContentGenerator /> : <Navigate to="/login" />} />
          <Route path="/social-media-post-generator" element={isAuthenticated ? <SocialMediaPostGenerator /> : <Navigate to="/login" />} />
          <Route path="/qr-code-generator" element={isAuthenticated ? <QRGenerator /> : <Navigate to="/login" />} />
          <Route path="/alt-text-generator" element={isAuthenticated ? <AltTextGenerator /> : <Navigate to="/login" />} />
          <Route path="/basic-translation" element={isAuthenticated ? <BasicTranslation /> : <Navigate to="/login" />} />
          <Route path="/metadata-keyword-generator" element={isAuthenticated ? <MetadataKeywordGenerator /> : <Navigate to="/login" />} />
          <Route path="/image-generation" element={isAuthenticated ? <ImageGeneration /> : <Navigate to="/login" />} />
          <Route path="/project-planning" element={isAuthenticated ? <ProjectPlanning /> : <Navigate to="/login" />} />
          <Route path="/inline-content-creator" element={isAuthenticated ? <InlineContentCreator /> : <Navigate to="/login" />} />
          <Route path="/email-generator" element={isAuthenticated ? <EmailGenerator /> : <Navigate to="/login" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
