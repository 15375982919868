import React, { useState } from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import ReactMarkdown from 'react-markdown';

const BlogPostGenerator = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [mainTopic, setMainTopic] = useState('');
    const [targetAudience, setTargetAudience] = useState('');
    const [keyPoints, setKeyPoints] = useState('');
    const [toneAndStyle, setToneAndStyle] = useState('');
    const [length, setLength] = useState('');
    const [keywords, setKeywords] = useState('');
    const [additionalInstructions, setAdditionalInstructions] = useState('');
    const [generatedPost, setGeneratedPost] = useState('');

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleGeneratePost = async () => {
        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL;
            const response = await fetch(`${baseUrl}/generate-blog-post`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ mainTopic, targetAudience, keyPoints, toneAndStyle, length, keywords, additionalInstructions })
            });
            const data = await response.json();
            setGeneratedPost(data.response.generatedBlogPost);
        } catch (error) {
            console.error('Error generating blog post:', error);
        }
    };

    return (
        <div className="relative flex flex-col min-h-screen">
            <NavBar toggleSidebar={toggleSidebar} />
            <div className="flex-1 p-4 flex flex-col">
                <div className="flex-1 bg-white shadow-md rounded-lg p-4 border-black border overflow-hidden">
                    <h2 className="text-2xl font-semibold mb-2">Blog Post Generator</h2>
                    <p className="text-gray-600 mb-6">Create blog posts using generative AI.</p>
                    <div className="flex mt-4 mb-8 h-full">
                        <div className="w-3/4 pr-4">
                            {generatedPost ? (
                                <div className="w-full h-full border rounded p-4 bg-gray-100 overflow-scroll">
                                    <ReactMarkdown>{generatedPost}</ReactMarkdown>
                                </div>
                            ) : (
                                <p className="text-gray-600">Generated blog post will appear here...</p>
                            )}
                        </div>
                        <div className="w-1/4 pl-4 flex flex-col space-y-4">
                            <div>
                                <textarea
                                    className="w-full border rounded p-2"
                                    placeholder="What is the main topic or idea for the blog post?"
                                    value={mainTopic}
                                    onChange={(e) => setMainTopic(e.target.value)}
                                ></textarea>
                            </div>
                            <div>
                                <select
                                    className="w-full border rounded p-2"
                                    value={targetAudience}
                                    onChange={(e) => setTargetAudience(e.target.value)}
                                >
                                    <option value="" disabled>Target Audience</option>
                                    <option value="young professionals">Young Professionals</option>
                                    <option value="students">Students</option>
                                    <option value="health enthusiasts">Health Enthusiasts</option>
                                    <option value="general audience">General Audience</option>
                                </select>
                            </div>
                            <div>
                                <textarea
                                    className="w-full border rounded p-2"
                                    placeholder="What are the key points or subtopics to be covered in the blog post?"
                                    value={keyPoints}
                                    onChange={(e) => setKeyPoints(e.target.value)}
                                ></textarea>
                            </div>
                            <div>
                                <select
                                    className="w-full border rounded p-2"
                                    value={toneAndStyle}
                                    onChange={(e) => setToneAndStyle(e.target.value)}
                                >
                                    <option value="" disabled>Tone and Style</option>
                                    <option value="formal">Formal</option>
                                    <option value="informal">Informal</option>
                                    <option value="friendly">Friendly</option>
                                    <option value="professional">Professional</option>
                                    <option value="humorous">Humorous</option>
                                </select>
                            </div>
                            <div>
                                <label className="block mb-2">Length of the Post</label>
                                <select
                                    className="w-full border rounded p-2"
                                    value={length}
                                    onChange={(e) => setLength(e.target.value)}
                                >
                                    <option value="" disabled>How long should the blog post be?</option>
                                    <option value="500 words">500 words</option>
                                    <option value="1000 words">1000 words</option>
                                    <option value="1500 words">1500 words</option>
                                </select>
                            </div>
                            <div>
                                <textarea
                                    className="w-full border rounded p-2"
                                    placeholder="Are there any specific keywords that should be included in the blog post?"
                                    value={keywords}
                                    onChange={(e) => setKeywords(e.target.value)}
                                ></textarea>
                            </div>
                            <div>
                                <textarea
                                    className="w-full border rounded p-2"
                                    placeholder="Are there any additional requirements or instructions for the blog post?"
                                    value={additionalInstructions}
                                    onChange={(e) => setAdditionalInstructions(e.target.value)}
                                ></textarea>
                            </div>
                            <button
                                className="w-full bg-blue-500 text-white rounded p-2 hover:bg-blue-600 transition duration-200"
                                onClick={handleGeneratePost}
                            >
                                Generate Blog Post
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default BlogPostGenerator;